//1aa95d7a-07b9-43bc-b2b3-b62dcc942d00
import axios, { AxiosResponse } from 'axios'
// import { getApiInstance } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import { store } from '@store/index'
import { IModuleBackendState } from '@store/module/backend/types'
import { IModuleStructureResponse } from '@resources/types/services/module'
const { REACT_APP_API_LOAD } = process.env

export const ModuleService = {
  get: async (): Promise<any> => {
    const response: AxiosResponse = await axios.get(
      `${REACT_APP_API_LOAD}/load`,
    )

    console.log('ModuleService->get/2', { response })

    return response
  },

  getLoadStructureByPlanIdAndStatus: async (
    planId: string,
    profile: string,
  ): Promise<IModuleStructureResponse> => {
    const { backend } = store.getState().moduleBackend as IModuleBackendState

    // const api = getApiInstance(backend.endpoints.structure.resources.baseUrl)

    const url = translateUrlParam(backend.endpoints.structure.resources.path, {
      planId,
      profile,
    })

    const response: AxiosResponse = await axios.get(
      `${REACT_APP_API_LOAD}/${url}`,
    )

    return response.data
  },
}
